import type { AppProps } from 'next/app';
import React from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the CSS
import '../assets/css/global.css';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import Error from './_error';
import 'setimmediate';

config.autoAddCss = false;

const MyApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={
        <Error title='Oops! something went wrong.' statusCode={500} />
      }
    >
      <Component {...pageProps} />
    </ErrorBoundary>
  );
};

export default MyApp;
