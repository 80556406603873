import React from 'react';
import ErrorService from '../../services/ErrorService';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  FallbackComponent: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo): void {
    ErrorService.notify('ErrorBoundary caught an error', error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.FallbackComponent;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
