import UniversalLogger from '../utils/UniversalLogger';

export default class ErrorService {
  static notify(
    message: string,
    error: Error,
    metadata: { [key: string]: Record<string, unknown> } = {}
  ): void {
    // Log to console for DataDog
    // to pick up the error
    UniversalLogger.error(message, error, metadata);
  }
}
